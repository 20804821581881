import { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import * as API from "../../Utils/Services/api";
import PlaceholderLoading from "react-placeholder-loading";
import "./FeaturedArticles.css";

const FeatuedArticles = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [blogList, setBlogList] = useState(null);

  useEffect(() => {
    (async () => {
      const resp = await API.GetBlog();
      setBlogList(resp.data.blog);
    })();
  }, []);

  return (
    <div className="featured-articles">
      <h4>Featured Blogs</h4>
      <div className="row">
        {blogList
          ? blogList.splice(0, 4).map((item, index) => (
              <div key={index} className="col-lg-3 mb-3">
                <div className="articles-block">
                  <div
                    onClick={() =>
                      navigate("/blogdetails", { state: { data: item } })
                    }
                  >
                    <div className="feauted-img">
                      {/* <img src="./Images/articles-img.webp" alt="" /> */}{" "}
                      <div className="featured-blog-img">
                        <img src={item.image} alt="category-img" />
                      </div>
                    </div>
                    <div className="articles-box">
                      {/* <p>API</p> */}
                      <div className="article-heading">{item.blogTitle}</div>
                      <div className="articles-author">{item.by}</div>
                      {/* <div className="articles-rating">
                  <i className="fa fa-star fa-xs pe-1"></i>
                  5.0 (4 votes)
                </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ))
          : Array.from({ length: 4 }).map((_, index) => (
              <div key={index} className="col-lg-3 mb-4">
                <div className="news-block">
                  <div>
                    <div className="feauted-img">
                      <PlaceholderLoading
                        shape="rect"
                        width="100%"
                        height={200}
                      />
                    </div>
                    <div className="news-box">
                      <PlaceholderLoading
                        shape="rect"
                        width="100%"
                        height={20}
                      />
                      <PlaceholderLoading
                        shape="rect"
                        width="80%"
                        height={20}
                        style={{ marginTop: 10 }}
                      />
                      <PlaceholderLoading
                        shape="rect"
                        width="60%"
                        height={20}
                        style={{ marginTop: 10 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
      </div>
      {location.pathname !== "/blogs" && (
        <div className="button-more-articles-div">
          <NavLink to="/blogs">
            <button className="see-more-articles m-auto">See more blogs</button>
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default FeatuedArticles;
