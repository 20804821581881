import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import CustomModal from "../../Common/Modal/CustomModal";
import PortfolioSummary from "./PortfolioSummary";
import PortfolioItem from "./PortfolioItem";
import AddCoinContent from "./AddCoinContent";
import AddTransactionContent from "./AddTransactionContent";
import EmptyPortfolio from "./EmptyPortfolio";
import * as API from "../../Utils/Services/api";
import { toast } from "react-toastify";
import "./Dashboard.css";
import RemoveTransaction from "./RemoveTransaction";
import PlaceholderPortfolioSummary from "./PlaceholderPortfolioSummary";
import PlaceholderPortfolioItem from "./PlaceholderPortfolioItem";

const Dashboard = () => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState("");
  const [show, setShow] = useState(false);
  const [coinsAdded, setCoinsAdded] = useState(false);
  const [transactionAdded, setTransactionAdded] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState(null);
  const [addToken, setAddToken] = useState(null);
  const [removeToken, setRemoveToken] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [starToggle, setStarToggle] = useState(false);
  const [toggleModel, setToggleModel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userTokenSelected, setUserTokenSelected] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("token") || !localStorage.getItem("name")) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 200);
    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  useEffect(() => {
    (async () => {
      const data = {
        page: 1,
        size: 30,
        category: debouncedSearchTerm ? debouncedSearchTerm : "",
      };
      const resp = await API.GetRWACurrencies(data);
      setSearchList(resp.data.currency);
    })();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    (async () => {
      try {
        const resp = await API.GetUserToken();
        setUserToken(resp.data);

        const data = [];
        resp.data.portfolioToken.forEach((item) => {
          data.push(item.tokenId);
          setUserTokenSelected(data);
        });

        if (resp.data.status) {
          setIsLoading(false);
        }
      } catch (err) {
        setUserToken(null);
        setUserTokenSelected(null);
        setIsLoading(false);
      }
    })();
  }, [toggleModel]);

  const handleAddCoin = async (data) => {
    const resp = await API.AddTokenPortfolio(data);
    setAddToken(resp.data);
    if (resp.data.status) {
      setCoinsAdded(true);
      setModalShow(false);
      setStarToggle(!starToggle);
      setToggleModel(!toggleModel);
      if (!starToggle) {
        toast.info(resp.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.info(resp.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setSearchTerm(null);
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTransactionSubmit = async (data) => {
    try {
      const resp = await API.PostUserTransaction(data);
      if (resp.data.status) {
        setTransactionAdded(true);
        setModalShow(false);
        setToggleModel(!toggleModel);
        return true;
      }
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
  };

  const removeTokenBodyContent = (
    <>Are you sure you want to remove this token from your portfolio?</>
  );
  const handleRemoveCoin = async (data) => {
    const resp = await API.AddTokenPortfolio(data.tokenId);
    setRemoveToken(resp.data);
    if (resp.data.status) {
      setCoinsAdded(false);
      setModalShow(false);
      setStarToggle(!starToggle);
      setToggleModel(!toggleModel);
      if (!starToggle) {
        toast.info(resp.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.info(resp.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  const removeTokenFooterContent = (
    <div className="m-auto submit-btn">
      <Button onClick={() => handleRemoveCoin(tokenId)}>Yes</Button>
    </div>
  );

  return (
    <>
      <div className="dashboard">
        <div className="header">
          <h3>RWA Dashboard</h3>
        </div>
        <div className="portfolio">
          <div className="portfolio-header">
            <h5 style={{ marginBottom: "0px" }}>My Portfolio</h5>
            <div className="submit-btn portfolio-add-token-btn">
              <div className="add-token-req-btn">
                <NavLink to="/tokenForm">
                  <button className="btn">Add Token Request</button>
                </NavLink>
              </div>
              <div className="add-coin-btn">
                <Button
                  onClick={() => {
                    setModalShow(true);
                    setModalType("addCoin");
                  }}
                >
                  Add Coin
                </Button>
                <CustomModal
                  show={modalShow && modalType === "addCoin"}
                  onHide={() => setModalShow(false)}
                  title="Add Coins"
                  bodyContent={
                    <AddCoinContent
                      searchList={searchList}
                      handleInputChange={handleInputChange}
                      searchTerm={searchTerm}
                      handleAddCoin={handleAddCoin}
                      starToggle={starToggle}
                      userToken={userToken}
                      userTokenSelected={userTokenSelected}
                    />
                  }
                />
              </div>
            </div>
          </div>
          <div className="portfolio-items">
            <div className="row">
              {isLoading ? (
                <>
                  <PlaceholderPortfolioSummary />
                  <PlaceholderPortfolioItem />
                </>
              ) : userToken ? (
                <>
                  <PortfolioSummary
                    totalAmount={userToken.totalAmount}
                    totalPercentage={userToken.totalPercentage}
                    totalReturn={userToken.totalReturn}
                  />
                  {userToken.portfolioToken.map((item, i) => (
                    <PortfolioItem
                      key={i}
                      item={item}
                      handleShow={handleShow}
                      setModalShow={setModalShow}
                      setModalType={setModalType}
                      setTokenId={setTokenId}
                    />
                  ))}
                </>
              ) : (
                <EmptyPortfolio
                  setModalShow={setModalShow}
                  setModalType={setModalType}
                />
              )}
            </div>
          </div>
        </div>
        <CustomModal
          show={modalShow && modalType === "addTransaction"}
          onHide={() => setModalShow(false)}
          title="Add Transaction"
          bodyContent={
            <AddTransactionContent
              tokenId={tokenId}
              handleTransactionSubmit={handleTransactionSubmit}
            />
          }
        />{" "}
        <CustomModal
          show={modalShow && modalType === "removeTransaction"}
          onHide={() => setModalShow(false)}
          title="Remove Transaction"
          bodyContent={
            <RemoveTransaction
              tokenId={tokenId}
              handleTransactionSubmit={handleTransactionSubmit}
              removeTokenFooterContent={removeTokenFooterContent}
            />
          }
        />
      </div>
    </>
  );
};

export default Dashboard;
