import Footer from "../../Common/Footer/Footer";
import Advertize from "../../Components/Advertise/Advertize";
import FeaturedArticles from "../../Components/FeaturesArticles/FeaturedArticles";
import { Highlights } from "../../Components/Highlights/Highlights";
import LatestNews from "../../Components/LatestNews/LatestNews";
import TableMarket from "../../Components/TableMarketCap/TableMarket";
import "./Home.css";

const Home = () => {
  return (
    <>
      {/* <Advertize /> */}
      <Highlights />
      <TableMarket />
      <LatestNews />
      <FeaturedArticles />
      <Advertize />
    </>
  );
};

export default Home;
