import { useState, useEffect, useContext } from "react";
import * as API from "../../Utils/Services/api";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import "./Login.css";
import { toast } from "react-toastify";
import ForgetPassModal from "../Modal/ForgetPassModal/ForgetPassModal";
import { AuthContext } from "../../App";

const Login = ({ setUser }) => {
  const navigate = useNavigate();
  const { setAuthLogin } = useContext(AuthContext);
  const [inputValue, setInputValue] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [login, setLogin] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("name"))
      navigate("/");
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function onPreview() {
    setIsVisible(!isVisible);
  }
  const inputHandler = (e) => {
    const { name, value } = e.target;

    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const onsubmit = async (data) => {
    try {
      const loginRes = await API.UserLogin(data);
      if (loginRes.data.status) {
        setUser(loginRes.data.userName);
        localStorage.setItem("token", loginRes.data.token);
        localStorage.setItem("name", loginRes.data.name);
        setAuthLogin(true);
        navigate("/");
        setLogin(true);
      }
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      <div className="login-pg">
        <div className="navbar-brand">
          <a className="header-logo-main-login-page" href="/">
            <img
              src="./Images/logo.png"
              alt="logo"
              style={{ width: "120px" }}
            />
          </a>
        </div>
        {/* <a className="navbar-brand" href="/">
          RWA CAMP
        </a> */}
        <div className="container">
          <form onSubmit={handleSubmit(onsubmit)}>
            <div className="login-form">
              <h4>LOGIN</h4>
              <div className="login-form-details mt-4">
                <div className="email-credentials">
                  <p className="pb-2">EMAIL</p>
                  <input
                    type="text"
                    placeholder="Email Address"
                    onChange={inputHandler}
                    name="email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Enter a valid email address",
                      },
                    })}
                  />{" "}
                  {errors.email && (
                    <p style={{ color: "red" }}>{errors.email.message}</p>
                  )}
                </div>
                <div className="password-credentials">
                  <div className="forgot-password">
                    <p className="pb-2">PASSWORD</p>
                    <div className="forgot-password-modal">
                      <button
                        type="button"
                        className="forgot-pass pb-2"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        Forgot password?
                      </button>
                    </div>
                  </div>
                  <div className="input-container">
                    <input
                      type={isVisible ? "text" : "password"}
                      onChange={inputHandler}
                      placeholder="Password"
                      className="input-with-icon"
                      name="password"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message:
                            "Password must be at least 8 characters long",
                        },
                      })}
                    />

                    {isVisible ? (
                      <i className="fa-solid fa-eye" onClick={onPreview}></i>
                    ) : (
                      <i
                        className="fa-solid fa-eye-slash"
                        onClick={onPreview}
                      ></i>
                    )}
                  </div>{" "}
                  {errors.password && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "small",
                        letterSpacing: "2.4px",
                      }}
                    >
                      {errors.password.message}
                    </p>
                  )}
                </div>
                <div className="sign-up-btn">
                  <button type="submit">LOGIN</button>
                </div>
                <div className="create-account">
                  <NavLink to="/signup">Create account</NavLink>
                  {/* <a href="/signup">Create account</a> */}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ForgetPassModal />
    </div>
  );
};

export default Login;
