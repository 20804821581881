import React from "react";
import { Pagination } from "react-bootstrap";
import "./Pagination.css";
const PaginationComponent = ({
  totalItems,
  pageSize,
  currentPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / pageSize);

  const handlePageChange = (pageNumber) => {
    onPageChange(pageNumber);
  };

  const renderPaginationItems = () => {
    let items = [];

    items.push(
      <Pagination.Prev
        key="prev"
        onClick={() => handlePageChange(currentPage > 1 ? currentPage - 1 : 1)}
        disabled={currentPage === 1}
      />
    );

    for (let number = 1; number <= totalPages; number++) {
      if (
        number === 1 ||
        number === totalPages ||
        (number >= currentPage - 1 && number <= currentPage + 1) ||
        number % 3 === 0
      ) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      } else if (number % 3 === 1 && number !== 1 && number !== totalPages) {
        items.push(<Pagination.Ellipsis key={`ellipsis-${number}`} />);
      }
    }

    items.push(
      <Pagination.Next
        key="next"
        onClick={() =>
          handlePageChange(
            currentPage < totalPages ? currentPage + 1 : totalPages
          )
        }
        disabled={currentPage === totalPages}
      />
    );

    return items;
  };

  return (
    <div className="pagination-pg">
      <div className="pagination">
        <Pagination>{renderPaginationItems()}</Pagination>
      </div>
    </div>
  );
};

export default PaginationComponent;
