import "./About.css";

const About = () => {
  return (
    <div className="about-pg">
      <h3>About RWACAMP TRACKER</h3>
      <div className="about-rwa">
        <div className="about-about-rwa">
          <b>
            RWA TRACKER is the world's most-referenced platform for tracking and
            analyzing Real World Assets (RWA) in the burgeoning blockchain
            space. Our mission is to make real-world assets discoverable and
            efficient globally by empowering users with unbiased, high-quality,
            and accurate information, enabling them to draw their own informed
            conclusions.
          </b>
        </div>
        <div className="rwacamp-about">
          <h6>Our Journey</h6>
          <p>
            Founded to address the unique challenges faced by traditional RWA
            investments, RWA TRACKER quickly grew to become a trusted source for
            users, institutions, and media. Our platform is frequently cited by
            industry experts and major news outlets for reliable data and
            insights on RWAs.
          </p>
          <h6>Our Mission</h6>
          <p>
            Our mission is to bridge the gap between traditional tangible assets
            and blockchain technology. We aim to provide comprehensive,
            accurate, and timely information to help our users navigate the
            complexities of RWA investments and take advantage of the
            opportunities presented by asset tokenization.
          </p>
          <h6>Our Growth</h6>{" "}
          <p>
            Since our inception, RWA TRACKER has established itself as a key
            player in the RWA space, trusted by a diverse user base. We continue
            to expand our offerings and improve our platform to meet the growing
            needs of our community.
          </p>
          <h6>Independence and Integrity</h6>{" "}
          <p>
            RWA TRACKER operates independently to ensure that our data and
            insights remain unbiased and reliable. We adhere to strict
            guidelines for listing and evaluating RWA projects, ensuring fair
            and independent assessments. Our commitment to transparency and
            integrity is at the core of everything we do.
          </p>
          <h6>Community Engagement</h6>{" "}
          <p>
            RWA TRACKER reaches millions of users annually through our website,
            mobile app, newsletter, blog, and social media channels (Twitter,
            Telegram, Facebook, Instagram). We also host annual conferences to
            bring together industry leaders, investors, and enthusiasts,
            fostering collaboration and innovation.
          </p>
          <h6>Contact Us</h6>{" "}
          <p>
            For advertising opportunities or inquiries about our product
            offerings, please email advertising@rwatracker.com. For editorial
            partnerships on our blog, write to us at social@rwatracker.com. For
            job openings at RWA TRACKER, check out our careers page.
          </p>
          <h6>Disclosure Policy</h6>
          <p>
            RWA TRACKER is committed to maintaining transparency and
            independence in all our operations. We strictly adhere to our
            independent listing criteria and evaluation methods, ensuring no
            preferential treatment for any project or asset. Our platform is
            designed to eliminate any possibility of bias, promoting a fair and
            open market for all users.
            <br />
            We believe in the transformative power of blockchain technology for
            real-world asset investments. Our goal is to support and promote
            this transformation by providing the most accurate and unbiased data
            on RWAs, driving the future of asset tokenization and investment.
            <br />
            Join us on our journey to make real-world asset investments more
            accessible, transparent, and efficient for everyone.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
