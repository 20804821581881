import "./TermsofService.css";

const TermsofService = () => {
  return (
    <div className="about-pg">
      <h3>Terms of Service</h3>
      <div className="about-rwa">
        <div className="about-about-rwa">
          <b>1. Introduction </b>
          <p>
            Welcome to RWA Tracker. These Terms of Service ("Terms") govern your
            use of our website and services. By accessing or using our website,
            you agree to be bound by these Terms. If you do not agree with any
            part of these Terms, you must not use our website or services.
          </p>
        </div>
        <div className="rwacamp-about">
          <b>2. Eligibility</b>
          <p>
            To use our services, you must be at least 18 years old and have the
            legal capacity to enter into these Terms. By using our website, you
            represent and warrant that you meet these eligibility requirements.
          </p>
          <b>3. User Accounts</b>

          <p>
            3.1 Account Creation: To access certain features of our website, you
            may need to create an account. You must provide accurate and
            complete information when creating your account and keep this
            information up-to-date.
            <br />
            3.2 Account Security: You are responsible for maintaining the
            confidentiality of your account credentials and for all activities
            that occur under your account. You agree to notify us immediately of
            any unauthorized use of your account.
            <br />
            3.3 Account Termination: We reserve the right to suspend or
            terminate your account at our discretion, without notice, for any
            reason, including if you violate these Terms.
            <br />
          </p>
          <b>4. Use of Services</b>
          <p>
            4.1 License: Subject to these Terms, we grant you a limited,
            non-exclusive, non-transferable, and revocable license to access and
            use our website and services for your personal, non-commercial use.
            <br />
            4.2 Prohibited Activities: You agree not to engage in any of the
            following prohibited activities:
            <br />
            Violating any applicable laws or regulations. Using our services for
            any fraudulent or unlawful purpose. Interfering with or disrupting
            the security or performance of our website. Attempting to gain
            unauthorized access to our systems or user accounts. Using our
            website to transmit any harmful or malicious content.
            <br />
          </p>
          <b>5. Content</b>

          <p>
            5.1 User Content: You retain ownership of any content you submit to
            our website. By submitting content, you grant us a worldwide,
            non-exclusive, royalty-free license to use, reproduce, modify, and
            distribute your content in connection with our services.
            <br />
            5.2 Prohibited Content: You agree not to submit any content that is
            illegal, offensive, or infringes on the rights of others. We reserve
            the right to remove any content that violates these Terms or is
            otherwise objectionable.
            <br />
            5.3 Intellectual Property: All content and materials on our website,
            including text, graphics, logos, and software, are the property of
            RWA Tracker or our licensors and are protected by intellectual
            property laws. You may not use, reproduce, or distribute any content
            from our website without our prior written permission.
            <br />
          </p>
          <b> 6. Disclaimers and Limitation of Liability</b>

          <p>
            6.1 No Financial or Legal Advice: The information provided on our
            website is for educational purposes only and should not be
            considered financial or legal advice. You should conduct your own
            due diligence before making any investment decisions.
            <br />
            6.2 Disclaimer of Warranties: Our website and services are provided
            "as is" and "as available" without any warranties of any kind,
            either express or implied. We do not warrant that our services will
            be uninterrupted or error-free.
            <br />
            6.3 Limitation of Liability: To the fullest extent permitted by law,
            RWA Tracker and its affiliates, officers, directors, employees, and
            agents shall not be liable for any indirect, incidental, special, or
            consequential damages arising out of or in connection with your use
            of our website or services.
            <br />
          </p>
          <b> 7. Indemnification</b>
          <p>
            You agree to indemnify and hold harmless RWA Tracker and its
            affiliates, officers, directors, employees, and agents from and
            against any claims, liabilities, damages, losses, and expenses
            arising out of or in connection with your use of our website or
            services, your violation of these Terms, or your infringement of any
            rights of another party.
          </p>
          <b>8. Modifications to Terms</b>
          <p>
            We reserve the right to modify these Terms at any time. If we make
            material changes, we will provide notice by posting the updated
            Terms on our website. Your continued use of our website after the
            effective date of the updated Terms constitutes your acceptance of
            the changes.
          </p>
          <b>9. Governing Law</b>
          <p>
            These Terms shall be governed by and construed in accordance with
            the laws of the jurisdiction in which RWA Tracker operates, without
            regard to its conflict of law principles.
          </p>
          <b>10. Contact Information</b>
          <p>
            If you have any questions or concerns about these Terms, please
            contact us at contact@rwatracker.com.
            <br />
            By using RWA Tracker, you acknowledge that you have read,
            understood, and agree to be bound by these Terms of Service.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsofService;
