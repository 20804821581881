import "./Advertize.css";

const Advertize = () => {
  return (
    <div className="advertize-pg">
      {/* <div className="row">
        <div className="col-lg-2 col-md-1"></div>
        <div className="col-lg-8 col-md-10">
          <div className="image-ad">
            <img src="./Images/ad_demo.jpg" alt="" />
          </div>
        </div>
        <div className="col-lg-2 col-md-1 "></div>
      </div> */}
      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-8">
          <div
            id="carouselExampleSlidesOnly"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active image-ad ">
                {" "}
                <img
                  src="./Images/ad_demo2.jpg"
                  className="d-block w-100"
                  alt="ad_demo2"
                />
              </div>
              <div className="carousel-item image-ad">
                <img
                  src="./Images/ad_demo.jpg"
                  className="d-block w-100"
                  alt="ad_demo"
                />
              </div>
              <div className="carousel-item image-ad">
                <img
                  src="./Images/ad_demo1.jpg"
                  className="d-block w-100"
                  alt="ad_demo1"
                />
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="col-lg-2"></div>
      </div>
    </div>
  );
};

export default Advertize;
