import { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import Searchbar from "../SearchBar/Searchbar";
import { AuthContext } from "../../App";
import Logo from "../../Assets/img/logo.png";
import "./Navbar.css";

const Navbar = () => {
  const { setAuthLogin } = useContext(AuthContext);
  const [userName, setUserName] = useState(
    localStorage.getItem("name") ?? null
  );

  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    setUserName(null);
    setAuthLogin(false);
  };

  return (
    <div className="navbar-bg inter-font-500">
      <nav className="navbar navbar-expand-lg ">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={Logo} alt="logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                {/* <a className="nav-link" aria-current="page" href="/">
                  Cryptocurrencies
                </a> */}
                <a className="nav-link" aria-current="page" href="/about">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Exchanges <span>(COMING SOON)</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Learn RWA <span>(COMING SOON)</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Products <span>(COMING SOON)</span>
                </a>
              </li>
            </ul>
            <form className="d-flex search-input me-2" role="search">
              <Searchbar />
            </form>
            {/* <button className="btn btn-outline-success me-2" type="submit">
              Login
            </button>
            <button className="btn btn-outline-success" type="submit">
              Sign Up
            </button> */}
            <button className="btn login-user" type="button">
              {userName ? (
                <div class="dropdown">
                  <button
                    class="btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <NavLink to="#">Hey, {userName}</NavLink>
                  </button>

                  <ul class="dropdown-menu navbar-dash">
                    <li>
                      <NavLink to="/dashboard">
                        {/* //class="dropdown-item" */}
                        Dashboard
                      </NavLink>
                    </li>
                    <li onClick={logoutHandler}>
                      <NavLink>Logout</NavLink>
                      {/* class="dropdown-item" */}
                    </li>
                  </ul>
                </div>
              ) : (
                <NavLink to="/login">
                  {/* <i className="fa-solid fa-user fa-lg"> </i> */}
                  Login
                </NavLink>
              )}
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
