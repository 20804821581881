import React from "react";
import Button from "react-bootstrap/Button";
import CustomModal from "../../Common/Modal/CustomModal";

const PortfolioItem = ({
  item,
  handleShow,
  setModalShow,
  setModalType,
  setTokenId,
}) => (
  <div className="col-lg-4 col-md-4 mt-4">
    <div className="portfolio-item">
      <div className="token-info-add-new">
        <div className="token-name-img">
          <div className="image-token-portfolio-user">
            <img src={item.image} alt="" />
          </div>
          <div className="token-name">
            <h5>{item.name}</h5>
          </div>
        </div>
        <div className="action-btn-portfolio">
          <div className="add-transaction-btn">
            <Button
              className="btn-add-transaction"
              onClick={() => {
                setModalShow(true);
                setModalType("addTransaction");
                setTokenId(item);
              }}
            >
              <i className="fa fa-circle-plus fa-lg"></i>
            </Button>
          </div>
          <div className="remove-transaction-btn">
            <Button
              className="btn-add-transaction"
              onClick={() => {
                // handleShow();
                setModalShow(true);
                setModalType("removeTransaction");
                setTokenId(item);
              }}
            >
              <i
                className="fa fa-circle-minus fa-lg"
                style={{ color: "red" }}
              ></i>
            </Button>
          </div>
        </div>
      </div>
      <div className="portfolio-tokenandprice">
        <div className="token-info">
          <h6>
            <b>({item.symbol.toUpperCase()})</b>
          </h6>
        </div>
        <div className="token-price">
          <b>$ {item?.currentPrice?.toFixed(2)}</b>
        </div>
      </div>
      <div className="data-after-transactions-added">
        <div className="market-cap-portfolio">
          <div className="market-cap-heading-portfolio">Total Spent</div>
          <div className="market-cap-amt">
            {Number(item.amount).toLocaleString()}
          </div>
        </div>
        <div className="market-cap-portfolio">
          <div className="market-cap-heading-portfolio">Quantity</div>
          <div className="market-cap-amt">
            {Number(item.quantity).toLocaleString()}
          </div>
        </div>
        <div className="market-cap-portfolio">
          <div className="market-cap-heading-portfolio">Price per token</div>
          <div className="market-cap-amt">${item.perUnit?.toFixed(2)}</div>
        </div>{" "}
        <div className="market-cap-portfolio">
          <div className="market-cap-heading-portfolio">Returns</div>
          <div
            className={`market-cap-amt ${
              item.return > 0
                ? "price-fluctuations-up"
                : "price-fluctuations-down"
            }`}
          >
            {item.return > 0 ? (
              <i className="fa fa-caret-up me-1"></i>
            ) : (
              <i className="fa fa-caret-down me-1"></i>
            )}
            ${Number(Math.abs(item.return?.toFixed(2))).toLocaleString()}
          </div>
        </div>{" "}
        <div className="market-cap-portfolio">
          <div className="market-cap-heading-portfolio">Return Percentage</div>
          <div
            className={`market-cap-amt ${
              item.returnPercentage > 0
                ? "price-fluctuations-up"
                : "price-fluctuations-down"
            }`}
          >
            {item.returnPercentage > 0 ? (
              <i className="fa fa-caret-up me-1"></i>
            ) : (
              <i className="fa fa-caret-down me-1"></i>
            )}
            {Number(
              Math.abs(item.returnPercentage?.toFixed(2))
            ).toLocaleString()}
            %
          </div>
        </div>
      </div>
      <div className="market-fluctuations">
        <div className="oneHour">
          <div className="oneHr-title">1H</div>
          <div
            className={`oneHr-price text-end fw-bold ${
              item.price_change_percentage_1h_in_currency > 0
                ? "price-fluctuations-up"
                : "price-fluctuations-down"
            }`}
          >
            {item.price_change_percentage_1h_in_currency > 0 ? (
              <i className="fa fa-caret-up me-1"></i>
            ) : (
              <i className="fa fa-caret-down me-1"></i>
            )}
            {Math.abs(item.price_change_percentage_1h_in_currency.toFixed(2))}%
          </div>
        </div>
        <div className="twentyFourHour">
          <div className="oneHr-title">24H</div>
          <div
            className={`oneHr-price text-end fw-bold ${
              item.price_change_percentage_24h > 0
                ? "price-fluctuations-up"
                : "price-fluctuations-down"
            }`}
          >
            {item.price_change_percentage_24h > 0 ? (
              <i className="fa fa-caret-up me-1"></i>
            ) : (
              <i className="fa fa-caret-down me-1"></i>
            )}
            {Math.abs(item.price_change_percentage_24h.toFixed(2))}%
          </div>
        </div>
        <div className="oneDay">
          <div className="oneHr-title">7D</div>
          <div
            className={`oneHr-price text-end fw-bold ${
              item.price_change_percentage_7d_in_currency > 0
                ? "price-fluctuations-up"
                : "price-fluctuations-down"
            }`}
          >
            {item.price_change_percentage_7d_in_currency > 0 ? (
              <i className="fa fa-caret-up me-1"></i>
            ) : (
              <i className="fa fa-caret-down me-1"></i>
            )}
            {Math.abs(item.price_change_percentage_7d_in_currency.toFixed(2))}%
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PortfolioItem;
