// components/VerifyEmail.js

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import * as API from "../../Utils/Services/api";
import Logo from "../../Assets/img/logo.png";
import "./Login.css";

const VerifyEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [verificationMessage, setVerificationMessage] = useState("");

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await API.GetVerifyEmail(token);
        // if (response.data.status) {

        // }
        setVerificationMessage(
          "Email verified successfully! You can now login."
        );
      } catch (error) {
        console.error("Verification error:", error);
        setVerificationMessage("Error verifying email. Please try again.");
      }
    };
    verifyToken();
  }, [token]);

  return (
    <div className="container-fluid">
      <div className="login-pg ">
        <div className="navbar-brand">
          <a className="header-logo-main-login-page" href="/">
            <img src={Logo} alt="logo" style={{ width: "120px" }} />
          </a>
        </div>
        <div className="container signup-verify">
          <div className="login-form login-form-create-acc ">
            <h2>Email Verification</h2>
            <p>{verificationMessage}</p>{" "}
            <div className="sign-up-btn">
              <button onClick={() => navigate("/login")}>Login</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
