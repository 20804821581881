import { Navbar } from "react-bootstrap";
// import img from "../../Common/User/img/404.png";
import "./Error.css";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import Footer from "../../Common/Footer/Footer";
const Error = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <section class="page_404">
        <div class="container">
          <div class="row">
            <div className="col-lg-2"></div>
            <div class="col-lg-8">
              <div class="col-sm-offset-1  text-center">
                <div class="four_zero_four_bg">
                  <h1 class="text-center ">404</h1>
                </div>

                <div class="contant_box_404">
                  <h3 class="h2">Looks like you're lost</h3>

                  <p>The page you are looking for is not available!</p>

                  <NavLink to="/" class="link_404">
                    Go to Home
                  </NavLink>
                </div>
              </div>
            </div>{" "}
            <div className="col-lg-2"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Error;
