import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { Table } from "react-bootstrap";
import { useMemo } from "react";
import PlaceholderLoading from "react-placeholder-loading";
import moment from "moment";

import Navbar from "../../Common/Navbar/Navbar";
import Footer from "../../Common/Footer/Footer";
import GridTokenDetail from "../../Components/GridTokenDetails/GridTokenDetail.jsx";
import GridTokenNews from "../../Components/GridTokenDetails/GridTokenNews.jsx";
import Tooltip from "../../Common/Tooltip/Tooltip.jsx";
import * as API from "../../Utils/Services/api";
import "chart.js/auto";
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";
import "./TokenDetail.css";

const verticalLinePlugin = {
  id: "verticalLinePlugin",
  afterDatasetsDraw: (chart, _) => {
    const { ctx, chartArea, scales } = chart;
    if (!chart.tooltip._active || chart.tooltip._active.length === 0) return;

    const activePoint = chart.tooltip._active[0];
    const { x } = activePoint.element;
    const { top, bottom } = chartArea;

    ctx.save();
    ctx.beginPath();
    ctx.strokeStyle = "#82ffcf";
    ctx.lineWidth = 1;
    ctx.moveTo(x, top);
    ctx.lineTo(x, bottom);
    ctx.stroke();
    ctx.restore();
  },
};

const TokenDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // const id = location.state.token;
  Chart.register(verticalLinePlugin);
  const [tokenDetails, setTokenDetails] = useState(null);
  const [graphMin, setGraphMin] = useState(0);
  const [graphMax, setGraphMax] = useState(0);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    (async () => {
      const resp = await API.TokenDetails(id);
      setTokenDetails(resp.data.detail);
      // setLoading(false);
    })();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      const resp = await API.CoinGraphData(id);
      const ohlcData = resp.data.graphData;

      const labels = ohlcData.map((record) =>
        moment(record[0]).format("hh:mm")
      );
      const prices = ohlcData.map((record) => record[4]);
      const volumes = ohlcData.map((record) => record[5]);

      const valuesAtIndex4 = ohlcData.map((subArray) => subArray[4]);

      setGraphMin(Math.max(...valuesAtIndex4));
      setGraphMax(Math.min(...valuesAtIndex4));

      setChartData({
        labels,
        datasets: [
          {
            label: "Price",
            data: prices,
            fill: true,
            backgroundColor: "#348f6ca8",
            borderColor: "#82ffcf",
            pointRadius: 0,
            pointHoverRadius: 5,
          },
        ],
      });
    };

    fetchData();
  }, []);

  const options = {
    scales: {
      x: {
        ticks: {
          step: 3,
        },
        display: true,
        grid: {
          display: false,
        },
      },
      y: {
        display: true,
        position: "right",
        grid: {
          display: true,
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
    },
    interaction: {
      mode: "nearest",
      intersect: false,
    },
    maintainAspectRatio: true,
  };

  // const { token } = location.state;
  // const handleTokenClick = (token) => {
  //   navigate("/tokendetails", { state: { token } });
  // };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <div className="token-details">
        {/* <h3>Token: {token.name}</h3>
      <p>{token.info}</p> */}

        <div className="grid-full-page">
          <div className="grid-view row">
            <div className="col-lg-3 col-md-3 col-12 grid-bg">
              <div className="grid-left">
                {tokenDetails ? (
                  <div>
                    <GridTokenDetail
                      name={tokenDetails.name}
                      image={tokenDetails.image.small}
                      price={tokenDetails.market_data.current_price.usd.toFixed(
                        2
                      )}
                      marketCap={Number(
                        tokenDetails.market_data.market_cap.usd.toFixed(2)
                      ).toLocaleString()}
                      marketChange={tokenDetails.market_data.price_change_percentage_24h_in_currency.usd.toFixed(
                        2
                      )}
                      // volumeDay={location.state.token.quote.USD.volume_24h.toFixed(
                      //   2
                      // )}
                      totalVolume={Number(
                        tokenDetails.market_data.total_volume.usd.toFixed(2)
                      ).toLocaleString()}
                      marketCapChange24H={tokenDetails.market_data.market_cap_change_percentage_24h.toFixed(
                        2
                      )}
                      // volume24H={location.state.token.quote.USD.volume_24h.toFixed(
                      //   2
                      // )}
                      maxSupply={
                        tokenDetails?.market_data.max_supply
                          ? Number(
                              tokenDetails?.market_data.max_supply.toFixed(2)
                            ).toLocaleString()
                          : "---"
                      }
                      circulatingSupply={Number(
                        tokenDetails.market_data.circulating_supply.toFixed(2)
                      ).toLocaleString()}
                      symbol={tokenDetails.symbol.toUpperCase()}
                      totalSupply={Number(
                        tokenDetails.market_data.total_supply.toFixed(2)
                      ).toLocaleString()}
                      fullyDilutedMarketCap={Number(
                        tokenDetails.market_data.fully_diluted_valuation.usd.toFixed(
                          2
                        )
                      ).toLocaleString()}
                      githubLink={tokenDetails.links.repos_url.github}
                      websiteLink={tokenDetails.links.homepage[0]}
                      tokeninfo={tokenDetails.description.en}
                      whitepaper={tokenDetails.links.whitepaper}
                      twitterLink={tokenDetails.links.twitter_screen_name}
                      telegramLink={
                        tokenDetails.links.telegram_channel_identifier
                      }
                      discordLink={tokenDetails.links.official_forum_url[0]}
                    />
                  </div>
                ) : (
                  <>
                    <PlaceholderLoading shape="rect" width={50} height={50} />
                    <PlaceholderLoading shape="rect" width={100} height={20} />
                    <PlaceholderLoading shape="rect" width={50} height={20} />

                    <div className="add-buttons">
                      <PlaceholderLoading
                        shape="rect"
                        width={120}
                        height={30}
                      />
                      <PlaceholderLoading
                        shape="rect"
                        width={150}
                        height={30}
                      />
                    </div>
                    <div className="market-cap-details-grid">
                      <PlaceholderLoading
                        shape="rect"
                        width={100}
                        height={20}
                      />
                      <PlaceholderLoading
                        shape="rect"
                        width={200}
                        height={20}
                      />
                    </div>
                    <div className="market-cap-details-grid market-cap-details-grid-2 mb-4">
                      <PlaceholderLoading
                        shape="rect"
                        width={100}
                        height={20}
                      />
                      <PlaceholderLoading
                        shape="rect"
                        width={200}
                        height={20}
                      />
                    </div>
                    <div className="market-cap-total-supply">
                      {[
                        "Volume/Market cap (24h)",
                        "Circulating supply",
                        "Total supply",
                        "Max. supply",
                        "Fully diluted valuation",
                      ].map((heading) => (
                        <div key={heading} className="volume-cap">
                          <PlaceholderLoading
                            shape="rect"
                            width={150}
                            height={20}
                          />
                          <PlaceholderLoading
                            shape="rect"
                            width={100}
                            height={20}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="official-links">
                      <PlaceholderLoading
                        shape="rect"
                        width={150}
                        height={20}
                      />
                      {[...Array(3)].map((_, index) => (
                        <PlaceholderLoading
                          key={index}
                          shape="rect"
                          width={100}
                          height={20}
                        />
                      ))}
                    </div>
                    <div className="social-links">
                      <PlaceholderLoading
                        shape="rect"
                        width={150}
                        height={20}
                      />
                      {[...Array(4)].map((_, index) => (
                        <PlaceholderLoading
                          key={index}
                          shape="rect"
                          width={100}
                          height={20}
                        />
                      ))}
                    </div>
                    <p className="mt-3">
                      <PlaceholderLoading
                        shape="rect"
                        width={200}
                        height={20}
                      />
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-7 col-md-9 col-12">
              <div className="grid-center">
                {/* <img src="./Images/dextool.jpg" alt="" /> */}
                {tokenDetails && (
                  <h4>
                    {tokenDetails.name} ({tokenDetails.symbol.toUpperCase()})
                    Chart
                  </h4>
                )}
                {graphMin && <Line data={chartData} options={options} />}
                <div className="tableBodyForMarketCap mt-5">
                  {tokenDetails && (
                    <h4 className="mb-4">
                      {tokenDetails.name} ({tokenDetails.symbol.toUpperCase()})
                      Market
                    </h4>
                  )}
                  <div
                    className="table-container"
                    style={{ overflowX: "auto", scrollbarWidth: "thin" }}
                  >
                    <Table className="MarketCapTable marketcaptableInsider">
                      <thead className="">
                        <tr>
                          <th className="text-center serial-no">#</th>
                          <th className="text-start category">Exchange</th>
                          <th className="text-end">
                            <div className="tr-eq-width">
                              <div className="tr-th-header">Pair</div>
                            </div>
                          </th>
                          <th className="text-end">
                            {" "}
                            <div className="tr-eq-width">
                              <div className="tr-th-header">Price</div>
                            </div>
                          </th>{" "}
                          <th className="text-end">
                            {" "}
                            <div className="tr-eq-width">
                              <div className="tr-th-header"> Spread </div>
                            </div>
                          </th>{" "}
                          <th className="text-end">
                            {" "}
                            <div className="tr-eq-width">
                              <div className="tr-th-header">24H Volume </div>
                            </div>
                          </th>{" "}
                          {/* <th className="text-end">
                            {" "}
                            <div className="tr-eq-width">
                              <div className="tr-th-header">Volume %</div>
                            </div>
                          </th>{" "} */}
                          <th className="text-end">
                            {" "}
                            <div className="tr-eq-width">
                              <div className="tr-th-header ">Trust Score</div>
                            </div>
                          </th>{" "}
                          <th className="text-end">
                            {" "}
                            <div className="tr-eq-width">
                              <div className="tr-th-header">Last Updated</div>
                              {/* <div className="tooltip-icon">
                                {" "}
                                <Tooltip text="Last updated " />
                              </div> */}
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {/* {rwaData.map((data, i) => ( */}
                        {tokenDetails
                          ? tokenDetails?.tickers
                              .slice(0, 10)
                              .map((item, i) => (
                                <tr>
                                  <td className="text-center">{i + 1}</td>
                                  <td className="text-start category">
                                    <div key={i}>
                                      <li style={{ listStyle: "none" }}>
                                        {item.market.name}
                                      </li>{" "}
                                    </div>
                                  </td>
                                  <td className="text-end price-fluctuations marketCapInsiderPairCol">
                                    <a
                                      target="__blank"
                                      href={item.trade_url}
                                      style={{
                                        textDecoration: "none",
                                        color: "black",
                                        width: "max-content",
                                      }}
                                    >
                                      {item.base.length > 5 ? "---" : item.base}
                                      /
                                      {item.target.length > 5
                                        ? "---"
                                        : item.target}
                                      <i class="fa-regular fa-share-from-square ps-2"></i>
                                    </a>
                                  </td>
                                  <td className="text-end">
                                    ${item.last.toFixed(4)}
                                  </td>{" "}
                                  <td className="text-end">
                                    {item.bid_ask_spread_percentage
                                      ? item.bid_ask_spread_percentage.toFixed(
                                          2
                                        )
                                      : "---"}
                                    %
                                  </td>
                                  <td className="text-end">
                                    $
                                    {Number(
                                      item.converted_volume.usd
                                    ).toLocaleString()}
                                  </td>
                                  <td className="text-center">
                                    {item.trust_score === "green" ? (
                                      <i
                                        class="fa-solid fa-circle"
                                        style={{ color: "green" }}
                                      ></i>
                                    ) : item.trust_score === "yellow" ? (
                                      <i
                                        class="fa-solid fa-circle"
                                        style={{ color: "yellow" }}
                                      ></i>
                                    ) : item.trust_score === "red" ? (
                                      <i
                                        class="fa-solid fa-circle"
                                        style={{ color: "red" }}
                                      ></i>
                                    ) : (
                                      "---"
                                    )}
                                  </td>
                                  <td className="text-end">Recently</td>
                                </tr>
                              ))
                          : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, i) => (
                              <tr>
                                <td className="text-end">
                                  <PlaceholderLoading
                                    shape="rect"
                                    // color="#E0E0E0"
                                    width={20}
                                    height={20}
                                  />
                                </td>
                                <td className="text-start category">
                                  <PlaceholderLoading
                                    shape="rect"
                                    // color="#E0E0E0"
                                    width={100}
                                    height={20}
                                  />
                                </td>
                                <td className="text-end fw-bold">
                                  <PlaceholderLoading
                                    shape="rect"
                                    // color="#E0E0E0"
                                    width={50}
                                    height={20}
                                  />
                                </td>
                                <td className="text-end fw-bold">
                                  <PlaceholderLoading
                                    shape="rect"
                                    // color="#E0E0E0"
                                    width={50}
                                    height={20}
                                  />
                                </td>
                                <td className="text-end fw-bold">
                                  <PlaceholderLoading
                                    shape="rect"
                                    // color="#E0E0E0"
                                    width={50}
                                    height={20}
                                  />
                                </td>
                                <td className="text-end fw-bold">
                                  <PlaceholderLoading
                                    shape="rect"
                                    // color="#E0E0E0"
                                    width={50}
                                    height={20}
                                  />
                                </td>
                                <td className="text-end">
                                  <PlaceholderLoading
                                    shape="rect"
                                    // color="#E0E0E0"
                                    width={100}
                                    height={20}
                                  />
                                </td>
                                <td className="text-end">
                                  <PlaceholderLoading
                                    shape="rect"
                                    // color="#E0E0E0"
                                    width={100}
                                    height={20}
                                  />
                                </td>
                                <td className="text-end">
                                  <PlaceholderLoading
                                    shape="rect"
                                    // color="#E0E0E0"
                                    width={100}
                                    height={20}
                                  />
                                </td>
                                <td className="text-end tracked-map">
                                  <PlaceholderLoading
                                    shape="rect"
                                    // color="#E0E0E0"
                                    width={100}
                                    height={20}
                                  />
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 d-none d-lg-block grid-bg">
              <div className="grid-right">
                <GridTokenNews />
              </div>
            </div>
          </div>{" "}
        </div>
      </div>{" "}
      <Footer />
    </>
  );
};

export default TokenDetail;
