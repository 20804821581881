import { useState, useEffect, useContext } from "react";
import { Placeholder, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PlaceholderLoading from "react-placeholder-loading";

import Tooltip from "../../Common/Tooltip/Tooltip";
import * as API from "../../Utils/Services/api";
import PaginationComponent from "../Pagination/PaginationComponent";
import SevenDays from "../../Common/Graph/SevenDays";
import { AuthContext } from "../../App";

import "./TableMarket.css";

const TableMarket = () => {
  const navigate = useNavigate();
  const { authLogin } = useContext(AuthContext);

  const [rwaCategoryList, setRwaCategoryList] = useState(null);
  const [rwaCurrencyData, setRwaCurrencyData] = useState(null);
  const [toggleStar, setToggleStar] = useState(false);
  const [category, setCategory] = useState("");
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [isUserLogin] = useState(
    localStorage.getItem("token") && localStorage.getItem("name") ? true : false
  );
  const itemsPerPage = 10;

  const handleToggleStar = async (e, id) => {
    e.stopPropagation();
    try {
      if (!localStorage.getItem("token") || !localStorage.getItem("name")) {
        return navigate("/login");
      }
      const resp = await API.FavCoin(id);

      if (resp.data.status) {
        const index = rwaCurrencyData.findIndex((coin) => coin.id === id);
        if (index === -1) return;

        const updatedCurrency = [...rwaCurrencyData];
        updatedCurrency[index].favCoin = !updatedCurrency[index].favCoin;
        setRwaCurrencyData(updatedCurrency);

        return toast.success(resp.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (err) {
      return toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    (async () => {
      const resp = await API.GetRWACategories();
      setRwaCategoryList(resp.data.category);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const data = {
        page,
        size: itemsPerPage,
        category,
      };
      const resp = await API.GetRWACurrencies(data);
      setRwaCurrencyData(resp.data.currency);
      setTotalItems(resp.data.total);
    })();
  }, [page, category, authLogin]);

  const handleTokenClick = (token) => {
    // console.log("token", token);
    navigate(`/tokendetails/${token}`);
  };

  const categoryHandler = (category) => {
    setCategory(category);
    setPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  return (
    <>
      <div className="table-market-cap">
        {/* <div className="main-tab">
          {rwaCategoryList &&
            rwaCategoryList.map((item, index) => (
              <div className="main-tab-categories">
                <button onClick={() => categoryHandler(item.category_id)}>
                  {item.name}
                </button>
              </div>
            ))}
        </div> */}
        <div className="my-2">
          <div className="tableBodyForMarketCap">
            <div
              className="table-container"
              style={{ overflowX: "auto", scrollbarWidth: "thin" }}
            >
              <Table className="MarketCapTable">
                <thead className="">
                  <tr>
                    {" "}
                    <th className="text-end serial-no"></th>
                    <th className="text-end serial-no">#</th>
                    <th className="text-start category">Name</th>
                    <th className="text-end">
                      <div className="tr-eq-width">
                        <div className="tr-th-header">Price</div>
                        <div className="tooltip-icon">
                          <Tooltip text="Average change in market price over the last 24 hours across all cryptocurrencies within a category." />
                        </div>
                      </div>
                    </th>
                    <th className="text-end">
                      {" "}
                      <div className="tr-eq-width">
                        <div className="tr-th-header">1h %</div>
                        <div className="tooltip-icon">
                          <Tooltip text="Cryptocurrencies that have increased the most in price over the last 1 hour." />
                        </div>
                      </div>
                    </th>{" "}
                    <th className="text-end">
                      {" "}
                      <div className="tr-eq-width">
                        <div className="tr-th-header">24h %</div>
                        <div className="tooltip-icon">
                          {" "}
                          <Tooltip text="Cryptocurrencies that have increased the most in price over the last 24 hours." />
                        </div>
                      </div>
                    </th>{" "}
                    <th className="text-end">
                      {" "}
                      <div className="tr-eq-width">
                        <div className="tr-th-header">7d %</div>
                        <div className="tooltip-icon">
                          {" "}
                          <Tooltip text="Total value of constituent traded (bought or sold) in the last 7 days" />
                        </div>
                      </div>
                    </th>{" "}
                    <th className="text-end">
                      {" "}
                      <div className="tr-eq-width">
                        <div className="tr-th-header">Market Cap</div>
                        <div className="tooltip-icon">
                          {" "}
                          <Tooltip text="Total number of market cap" />
                        </div>
                      </div>
                    </th>{" "}
                    <th className="text-end">
                      {" "}
                      <div className="tr-eq-width">
                        <div className="tr-th-header">Total Volume</div>
                        <div className="tooltip-icon">
                          {" "}
                          <Tooltip text="Total volume of the token" />
                        </div>
                      </div>
                    </th>
                    <th className="text-end">
                      {" "}
                      <div className="tr-eq-width">
                        <div className="tr-th-header">Circulating Supply</div>
                        <div className="tooltip-icon">
                          {" "}
                          <Tooltip text="Circulating supply of the token" />
                        </div>
                      </div>
                    </th>
                    <th className="text-center">
                      {" "}
                      <div className="tr-graph">
                        <div className="tr-th-header">Last 7 Days</div>
                        <div className="tooltip-icon">
                          {" "}
                          <Tooltip text="Visual representation of the average market cap trend over the last 7 days." />
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {rwaCurrencyData
                    ? rwaCurrencyData.map((data, i) => (
                        <tr
                          key={i}
                          onClick={() => handleTokenClick(data.id)}
                          style={{ cursor: "pointer" }}
                        >
                          {" "}
                          <td className="text-end">
                            <button
                              onClick={(e) => handleToggleStar(e, data.id)}
                              style={{
                                border: "none ",
                                background: "transparent",
                              }}
                            >
                              {data.favCoin && isUserLogin ? (
                                <i
                                  className="fa fa-star fa-xs"
                                  style={{ color: "orangeRed" }}
                                ></i>
                              ) : (
                                <i class="fa-regular fa-star fa-xs"></i>
                              )}
                            </button>
                          </td>
                          <td className="text-end">{data.rank}</td>
                          <td className="text-start category category-img">
                            <img src={data.image} alt={data.name} />
                            {data.name} ({data.symbol.toUpperCase()})
                          </td>
                          <td className="text-end fw-bold">
                            ${data?.current_price?.toFixed(2)}
                          </td>
                          <td
                            className={`text-end fw-bold ${
                              data.price_change_percentage_1h_in_currency > 0
                                ? "price-fluctuations-up"
                                : "price-fluctuations-down"
                            }`}
                          >
                            {data.price_change_percentage_1h_in_currency > 0 ? (
                              <>
                                <i className="fa fa-caret-up me-1"></i>
                                {Math.abs(
                                  data.price_change_percentage_1h_in_currency.toFixed(
                                    2
                                  )
                                )}
                              </>
                            ) : (
                              <>
                                <i className="fa fa-caret-down me-1"></i>
                                {Math.abs(
                                  data.price_change_percentage_1h_in_currency.toFixed(
                                    2
                                  )
                                )}
                              </>
                            )}
                          </td>{" "}
                          <td
                            className={`text-end fw-bold ${
                              data.price_change_percentage_24h > 0
                                ? "price-fluctuations-up"
                                : "price-fluctuations-down"
                            }`}
                          >
                            {data.price_change_percentage_24h > 0 ? (
                              <i className="fa fa-caret-up me-1"></i>
                            ) : (
                              <i className="fa fa-caret-down me-1"></i>
                            )}
                            {Math.abs(
                              data.price_change_percentage_24h.toFixed(2)
                            )}
                          </td>
                          <td
                            className={`text-end fw-bold ${
                              data.price_change_percentage_7d_in_currency > 0
                                ? "price-fluctuations-up"
                                : "price-fluctuations-down"
                            }`}
                          >
                            {data.price_change_percentage_7d_in_currency > 0 ? (
                              <i className="fa fa-caret-up me-1"></i>
                            ) : (
                              <i className="fa fa-caret-down me-1"></i>
                            )}
                            {Math.abs(
                              data.price_change_percentage_7d_in_currency.toFixed(
                                2
                              )
                            )}
                          </td>
                          <td className="text-end">
                            $
                            {Number(
                              data.market_cap.toFixed(2)
                            ).toLocaleString()}
                          </td>
                          <td className="text-end">
                            $
                            {Number(
                              data.total_volume.toFixed(2)
                            ).toLocaleString()}
                          </td>
                          <td className="text-end">
                            $
                            {Number(
                              data.circulating_supply.toFixed(2)
                            ).toLocaleString()}
                          </td>
                          <td className="text-end tracked-map">
                            <SevenDays
                              price={
                                data.price_change_percentage_7d_in_currency
                              }
                              datum={data.sparkline_in_7d.price}
                            />
                          </td>
                        </tr>
                      ))
                    : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, i) => (
                        <tr>
                          <td className="text-end">
                            <PlaceholderLoading
                              shape="rect"
                              // color="#E0E0E0"
                              width={20}
                              height={20}
                            />
                          </td>
                          <td className="text-start category">
                            <PlaceholderLoading
                              shape="rect"
                              // color="#E0E0E0"
                              width={20}
                              height={20}
                            />
                          </td>
                          <td className="text-end fw-bold">
                            <PlaceholderLoading
                              shape="rect"
                              // color="#E0E0E0"
                              width={100}
                              height={20}
                            />
                          </td>
                          <td className="text-end fw-bold">
                            <PlaceholderLoading
                              shape="rect"
                              // color="#E0E0E0"
                              width={50}
                              height={20}
                            />
                          </td>
                          <td className="text-end fw-bold">
                            <PlaceholderLoading
                              shape="rect"
                              // color="#E0E0E0"
                              width={50}
                              height={20}
                            />
                          </td>
                          <td className="text-end fw-bold">
                            <PlaceholderLoading
                              shape="rect"
                              // color="#E0E0E0"
                              width={50}
                              height={20}
                            />
                          </td>
                          <td className="text-end">
                            <PlaceholderLoading
                              shape="rect"
                              // color="#E0E0E0"
                              width={100}
                              height={20}
                            />
                          </td>
                          <td className="text-end">
                            <PlaceholderLoading
                              shape="rect"
                              // color="#E0E0E0"
                              width={100}
                              height={20}
                            />
                          </td>
                          <td className="text-end">
                            <PlaceholderLoading
                              shape="rect"
                              // color="#E0E0E0"
                              width={100}
                              height={20}
                            />
                          </td>
                          <td className="text-end tracked-map">
                            <PlaceholderLoading
                              shape="rect"
                              // color="#E0E0E0"
                              width={100}
                              height={20}
                            />
                          </td>
                          <td className="text-end tracked-map">
                            <PlaceholderLoading
                              shape="rect"
                              // color="#E0E0E0"
                              width={100}
                              height={20}
                            />
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      <PaginationComponent
        totalItems={totalItems}
        pageSize={itemsPerPage}
        currentPage={page}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default TableMarket;
