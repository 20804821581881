import { useEffect, useState } from "react";
import * as API from "../../Utils/Services/api";
import PlaceholderLoading from "react-placeholder-loading";
import { useLocation, useParams } from "react-router-dom";
import "./News.css";

const NewsDeatils = () => {
  const { id } = useParams();
  const location = useLocation();
  const [news, setNews] = useState(null);
  //   const data = location.state.id;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    (async () => {
      const resp = await API.GetNewsDetails(id);
      // console.log("res", resp.data);
      setNews(resp.data.news);
    })();
  }, []);

  return (
    <section class="blog-grid-area">
      <div class="container">
        <div class="row">
          <div className="col-lg-2"> </div>
          <div class="col-lg-8">
            <div class="bocPost-wrapper">
              <article class="bocPost-item-wrapper mb-120">
                <div class="bocPost-item-thumb p-relative">
                  <div className="blogDetails-img">
                    {news?.image?.url ? (
                      <img src={news?.image?.url} alt="category-img" />
                    ) : (
                      <PlaceholderLoading
                        shape="rect"
                        width="100%"
                        height={300}
                      />
                    )}
                  </div>
                </div>
                <div class="bocPost-meta">
                  {/* <span>
                    <i class="fa-solid fa-user"></i>
                    {data.by}
                  </span> */}
                  <span>
                    {news?.date ? (
                      <>
                        <i className="fa-regular fa-calendar-days"></i>
                        {news?.date}
                      </>
                    ) : (
                      <PlaceholderLoading
                        shape="rect"
                        width={100}
                        height={20}
                      />
                    )}
                  </span>
                </div>
                <h3 class="bocPost-title">
                  {news?.title ? (
                    news?.title
                  ) : (
                    <PlaceholderLoading shape="rect" width="100%" height={30} />
                  )}
                </h3>
                <p>
                  {news?.content ? (
                    news?.content
                  ) : (
                    <>
                      <PlaceholderLoading
                        shape="rect"
                        width="100%"
                        height={20}
                      />
                      <PlaceholderLoading
                        shape="rect"
                        width="100%"
                        height={20}
                      />
                      <PlaceholderLoading
                        shape="rect"
                        width="100%"
                        height={20}
                      />
                    </>
                  )}
                </p>
                <div class="bocPost-blockquote p-relative">
                  <blockquote>
                    <p>
                      {news?.image?.description ? (
                        news?.image?.description
                      ) : (
                        <PlaceholderLoading
                          shape="rect"
                          width="100%"
                          height={20}
                        />
                      )}
                    </p>
                  </blockquote>
                </div>

                {/* <p>{data.conclusion}</p> */}
              </article>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    </section>
  );
};

export default NewsDeatils;
