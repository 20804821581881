import { useEffect, useState } from "react";
import "./Highlights.css";
import * as API from "../../Utils/Services/api";
import PlaceholderLoading from "react-placeholder-loading";
import { right } from "@popperjs/core";

const cryptoData = [
  {
    image:
      "https://res.cloudinary.com/dbtsrjssc/image/upload/v1721115796/DALL_E-2024-07-16-12.34_1_rr5mxe.png",
    name: "MAGA Hat",
    symbol: "MAGA",
    price: 0.00037911,
    change: 0.6,
  },
  {
    image:
      "https://res.cloudinary.com/dbtsrjssc/image/upload/v1721115796/DALL_E-2024-07-16-12.39_1_cch9te.png",
    name: "Blast",
    symbol: "BLAST",
    price: 0.02387,
    change: 3.7,
  },
  {
    image:
      "https://res.cloudinary.com/dbtsrjssc/image/upload/v1721115796/DALL_E-2024-07-16-12.38_1_ithgm1.png",
    name: "Solana",
    symbol: "SOL",
    price: 148.07,
    change: 8.4,
  },
];

export const Highlights = () => {
  const [highlightsData, setHighlightsData] = useState(null);
  const [trendingData, setTrendingData] = useState(null);
  const [topCoin, setTopCoin] = useState(null);
  const [showMore, setShowMore] = useState(false);
  //   const [data, setData] = useState([]);
  function handleClick() {
    setShowMore(!showMore);
  }
  useEffect(() => {
    (async () => {
      const resp = await API.HighlightsRWAData();
      setHighlightsData(resp.data.highlightData);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const resp = await API.TrendingData();
      setTrendingData(resp.data.trend);
      setTopCoin(resp.data.top.splice(0, 3));
    })();
  }, []);
  return (
    <>
      <div className="highlights-pg">
        <h4>RWA Prices by Market Cap</h4>

        <div className="highlights-desc">
          <p>
            The global Real World Assets (RWA) market cap today stands at $2.42
            trillion, reflecting a 1.7% increase over the last 24 hours.
            <button className="read-more-btn" onClick={handleClick}>
              {showMore ? "Hide" : "Show More"}
            </button>
            {showMore && (
              <p className="mt-3">
                The total trading volume for RWAs in the past day is $72.3
                billion. Within the RWA market, real estate-backed tokens
                dominate with a 50.4% share, followed by commodity-backed tokens
                at 17.2%. Currently, there are 14,842 RWAs being tracked.
                Notably, the largest gainers in the industry right now are real
                estate-backed and commodity-backed tokens, showcasing strong
                growth and investor interest in these asset types.
              </p>
            )}
          </p>
        </div>

        <div className="trending-tokens">
          <div className="trending-heading">
            <i className="fa fa-fire me-1 fa-lg"></i>
            Trending RWA
          </div>
          <div className="trending-tokens-content">
            <div className="scrollable-trending">
              <ul className="trending-tokens-individual">
                {trendingData &&
                  trendingData.map((item, index) => (
                    <li className="me-2">
                      <span>|</span>
                      <span>#{index + 1}</span>{" "}
                      <span>
                        <img
                          src={item.image}
                          alt="coins"
                          width="20"
                          height="20"
                        />
                      </span>{" "}
                      <span>
                        {item.name} ({item.symbol.toUpperCase()})
                      </span>{" "}
                      {item.price_change_percentage_24h > 0 ? (
                        <span className="price-fluctuations-up">
                          <i className="fa fa-caret-up ms-2"></i>{" "}
                          {Math.abs(
                            item.price_change_percentage_24h.toFixed(2)
                          )}
                        </span>
                      ) : (
                        <span className="price-fluctuations-down">
                          <i className="fa fa-caret-down ms-2"></i>{" "}
                          {Math.abs(
                            item.price_change_percentage_24h.toFixed(2)
                          )}
                        </span>
                      )}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="highlights-graph-data">
          <div className="row">
            <div className="col-lg-4">
              <div className="market-cap-graphs">
                <div className="graph-1">
                  {highlightsData ? (
                    <>
                      <div className="graph-detail d-flex align-items-center justify-content-center ">
                        <div className="graph-details-img">
                          <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1721050646/Asset_2_zlva5u.png"
                            alt=""
                          />
                        </div>
                        <div className="graph-val">
                          <div className="graph-value">
                            $
                            {Number(
                              highlightsData.market_cap.toFixed(2)
                            ).toLocaleString()}
                          </div>

                          <div className="value-type">
                            Market Cap
                            <span
                              className={`fw-bold ${
                                highlightsData.market_cap_change_24h > 0
                                  ? "price-fluctuations-up"
                                  : "price-fluctuations-down"
                              }`}
                            >
                              {highlightsData.market_cap_change_24h.toFixed(2) >
                              0 ? (
                                <>
                                  <i className="fa fa-caret-up ms-2"></i>{" "}
                                  {Math.abs(
                                    highlightsData.market_cap_change_24h.toFixed(
                                      2
                                    )
                                  )}
                                  %
                                </>
                              ) : (
                                <>
                                  <i className="fa fa-caret-down ms-2"></i>{" "}
                                  {Math.abs(
                                    highlightsData.market_cap_change_24h.toFixed(
                                      2
                                    )
                                  )}
                                  %
                                </>
                              )}
                            </span>{" "}
                          </div>
                        </div>
                      </div>
                      <div className="graph-map">
                        {highlightsData.market_cap_change_24h.toFixed(2) > 0 ? (
                          <img
                            src="./Images/graphup.png"
                            alt="total_market"
                            className="graph-up-img"
                          />
                        ) : (
                          <img
                            src="./Images/total_market_cap.svg"
                            alt="total_market"
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <div className="graph-value">
                          <PlaceholderLoading
                            shape="rect"
                            color="#fff"
                            width={100}
                            height={20}
                          />
                        </div>

                        <div className="value-type">
                          <PlaceholderLoading
                            shape="rect"
                            color="#fff"
                            width={100}
                            height={20}
                          />
                          <span className="fw-bold">
                            <PlaceholderLoading
                              shape="rect"
                              color="#fff"
                              width={50}
                              height={20}
                            />
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>{" "}
                {highlightsData ? (
                  <>
                    <div className="graph-2">
                      <div className="graph-detail d-flex align-items-center justify-content-center ">
                        {" "}
                        <div className="graph-details-img">
                          <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1721050646/Asset_3_pwt6aa.png"
                            alt=""
                          />
                        </div>{" "}
                        <div className="graph-val">
                          <div className="graph-value">
                            $
                            {Number(
                              highlightsData.volume_24h.toFixed(2)
                            ).toLocaleString()}
                          </div>

                          <div className="value-type">24h trading volume</div>
                        </div>
                      </div>
                      <div className="graph-map">
                        {highlightsData.volume_24h.toFixed(2) > 0 ? (
                          <img
                            src="./Images/graphup.png"
                            alt="total_market"
                            className="graph-up-img"
                          />
                        ) : (
                          <img
                            src="./Images/total_market_cap.svg"
                            alt="total_market"
                          />
                        )}
                      </div>
                    </div>{" "}
                  </>
                ) : (
                  <div className="graph-2">
                    <div className="graph-value">
                      <PlaceholderLoading
                        shape="rect"
                        color="white"
                        width={100}
                        height={20}
                      />
                    </div>

                    <div className="value-type">
                      <PlaceholderLoading
                        shape="rect"
                        color="white"
                        width={100}
                        height={20}
                      />
                      <span className="fw-bold">
                        <PlaceholderLoading
                          shape="rect"
                          color="white"
                          width={50}
                          height={20}
                        />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="highlights-trending-rwa">
                <div className="trending-headers">
                  <div className="trending-heading">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1721114157/Asset_5_jvdcty.png"
                      alt=""
                      width={20}
                      style={{ marginRight: "5px" }}
                    />
                    Top
                  </div>
                  <div className="trending-view-more">
                    View More <i className="fa fa-angle-right fa-xs"></i>
                  </div>
                </div>
                <div className="trending-data-table">
                  {topCoin ? (
                    topCoin.map((data, index) => (
                      <>
                        <div className="trending-data-fetch">
                          <div key={index} className="token-name">
                            <span>
                              <img
                                src={data.image}
                                alt="coins"
                                width="20"
                                height="20"
                              />
                            </span>{" "}
                            {data.name}{" "}
                            <span>({data.symbol.toUpperCase()})</span>{" "}
                          </div>
                          <div className="token-real-time-data">
                            ${data.current_price.toFixed(5)}{" "}
                            <span
                              className={`${
                                data.price_change_percentage_24h > 0
                                  ? "price-fluctuations-up"
                                  : "price-fluctuations-down"
                              }`}
                            >
                              {data.price_change_percentage_24h > 0 ? (
                                <i className="fa fa-caret-up ms-2"></i>
                              ) : (
                                <i className="fa fa-caret-down ms-2"></i>
                              )}{" "}
                              {Math.abs(
                                data.price_change_percentage_24h.toFixed(2)
                              )}
                              %
                            </span>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <>
                      <div className="graph-2">
                        <div className="graph-value">
                          <PlaceholderLoading
                            shape="rect"
                            color="white"
                            width={100}
                            height={20}
                          />
                        </div>
                        <div className="value-type">
                          <PlaceholderLoading
                            shape="rect"
                            color="white"
                            width={100}
                            height={20}
                          />
                        </div>{" "}
                        <br />
                      </div>{" "}
                      <div className="graph-2">
                        <div className="graph-value">
                          <PlaceholderLoading
                            shape="rect"
                            color="white"
                            width={100}
                            height={20}
                          />
                        </div>
                        <div className="value-type">
                          <PlaceholderLoading
                            shape="rect"
                            color="white"
                            width={100}
                            height={20}
                          />
                        </div>{" "}
                        <br />
                      </div>{" "}
                      <div className="graph-2">
                        <div className="graph-value">
                          <PlaceholderLoading
                            shape="rect"
                            color="white"
                            width={100}
                            height={20}
                          />
                        </div>
                        <div className="value-type">
                          <PlaceholderLoading
                            shape="rect"
                            color="white"
                            width={100}
                            height={20}
                          />
                        </div>{" "}
                        <br />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="highlights-trending-rwa">
                <div className="trending-headers">
                  <div className="trending-heading">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1721114157/Asset_4_syc8a9.png"
                      alt=""
                      width={20}
                      style={{ marginRight: "5px" }}
                    />
                    Newly Added
                  </div>
                  <div className="trending-view-more">
                    View More <i className="fa fa-angle-right fa-xs"></i>
                  </div>
                </div>
                <div className="trending-data-table">
                  {cryptoData.map((data, index) => (
                    <>
                      <div className="trending-data-fetch">
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="token-img-static">
                            <img
                              src={data.image}
                              alt=""
                              width={20}
                              height={20}
                              style={{ marginRight: "5px" }}
                            />
                          </div>
                          <div key={index} className="token-name">
                            {data.name}
                          </div>
                        </div>
                        <div className="token-real-time-data">
                          ${data.price.toFixed(5)} <span>({data.change}%)</span>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
