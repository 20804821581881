import * as API from "../../Utils/Services/api";
import "./../../Pages/TokenDetails/TokenDetail.css";
import LatestNews from "../LatestNews/LatestNews";
import PlaceholderLoading from "react-placeholder-loading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "./GridTokenDetail.css";
const GridTokenDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [newsList, setNewsList] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      const resp = await API.GetNews();
      setNewsList(resp.data.news);
    })();
  }, []);
  return (
    <div className="grid-news">
      <div className="section-heading">
        <div className="section-top">
          <h6>RWA News</h6>
        </div>
      </div>
      {newsList && newsList.length > 0
        ? newsList.slice(0, 4).map((item, index) => (
            <div
              className="news-block news-grid-new"
              onClick={() => navigate(`/newsdetails/${item.id}`)}
            >
              <div className="feauted-img">
                <img src={item.image.url} alt="" />{" "}
                {/* <div className="featured-blog-img"></div> */}
              </div>
              <div className="news-box newsbox-new">
                {" "}
                <div className="news-heading news-new-headinggrid">
                  {item.title}
                </div>
                {/* <div className="news-heading">{item.image.description}</div> */}
                <div className="news-source news-source-p-grid">
                  {item.image.description.slice(0, 50)}...{" "}
                  <span> Read More</span>
                </div>
                <div className="news-time  news-new-time-grid">{item.date}</div>
              </div>
            </div>
          ))
        : Array.from({ length: 4 }).map((_, index) => (
            <div key={index} className="col-lg-3 mb-2">
              <div className="news-block">
                <div>
                  <div className="feauted-img">
                    <PlaceholderLoading shape="rect" width={300} height={160} />
                  </div>
                  <div className="news-box">
                    <PlaceholderLoading shape="rect" width={260} height={20} />
                    <PlaceholderLoading shape="rect" width={200} height={20} />
                    <PlaceholderLoading
                      shape="rect"
                      width={70}
                      height={20}
                      style={{ marginTop: 10 }}
                    />
                    <PlaceholderLoading
                      shape="rect"
                      width={40}
                      height={20}
                      style={{ marginTop: 10 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
    </div>
  );
};

export default GridTokenDetail;
