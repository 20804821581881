import { useState, useEffect, createContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Navbar from "./Common/Navbar/Navbar";
import Login from "./Common/User/Login";
import VerifyEmail from "./Common/User/VerifyEmail";
import SignUp from "./Common/User/SignUp";
import Home from "./Pages/Home/Home";
import TokenDetail from "./Pages/TokenDetails/TokenDetail";
import Footer from "./Common/Footer/Footer";
import About from "./Pages/About/About";
import TermsofService from "./Pages/TermsofService/TermsofService";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import News from "./Pages/News/News";
import Faq from "./Pages/FAQ/Faq";
import Blogs from "./Pages/Blogs/Blogs";
import AdPrivacy from "./Pages/Adprivacy/AdPrivacy";
import ForgetPassModal from "./Common/Modal/ForgetPassModal/ForgetPassModal";
import SetPassword from "./Components/SetPassword/SetPassword";
import BlogDetailed from "./Pages/Blogs/BlogDetailed";
import Error from "./Pages/Error/Error";

import "./App.css";
import NewsDeatils from "./Pages/News/NewsDeatils";
import Dashboard from "./Pages/Dashboard/Dashboard";
import TokenForm from "./Pages/Dashboard/TokenForm";

const MainLayout = ({ user }) => (
  // <div className="container-fluid">
  <>
    <Navbar user={user} />
    <div className="container">
      <Outlet />
      <Footer />
      {/* </div> */}
    </div>
  </>
);

export const AuthContext = createContext();

function App() {
  const [user, setUser] = useState(null);
  const [authLogin, setAuthLogin] = useState();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const decodeToken = jwtDecode(token);
      const expToken = decodeToken.exp * 1000;

      if (Date.now() >= expToken) {
        localStorage.removeItem("token");
        localStorage.removeItem("name");
      }
    }
  }, []);

  return (
    <div className="inter-font">
      <AuthContext.Provider value={{ authLogin, setAuthLogin }}>
        <Router>
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="/termsofservice" element={<TermsofService />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/news" element={<News />} />
              {/* <Route path="/articles" element={<Blogs />} /> */}
              <Route path="/faq" element={<Faq />} />{" "}
              <Route path="/blogs" element={<Blogs />} />{" "}
              <Route path="/adprivacy" element={<AdPrivacy />} />
              <Route path="/blogdetails" element={<BlogDetailed />} />
              <Route path="/newsdetails/:id" element={<NewsDeatils />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/tokenForm" element={<TokenForm />} />
              <Route path="/404" element={<Error />} />
              <Route path="/*" element={<Error />} />
            </Route>
            <Route path="/login" element={<Login setUser={setUser} />} />
            <Route path="/signup" element={<SignUp />} />{" "}
            <Route path="/verify/:token" element={<VerifyEmail />} />{" "}
            <Route path="/tokendetails/:id" element={<TokenDetail />} />
            <Route path="/reset-password/:token" element={<SetPassword />} />
            {/* <Route path="/reset-password" element={<SetPassword />} /> */}
          </Routes>
        </Router>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
